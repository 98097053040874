<template>
  <validation-observer ref="validationObserver">
    <b-form @submit.prevent="handleUpdate" class="form-container">
      <b-col>
        <h4 class="mb-2">Wybierz stronę</h4>
        <b-form-group label="Strona" label-for="v-page-select">
          <v-select
            id="v-page-select"
            label="value"
            placeholder="Wybierz stronę"
            v-model="form.page"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="sitesAvailable"
            @input="handleChangePage" />
        </b-form-group>

        <h4 class="mb-2 mt-2">Konfiguracja</h4>
        <b-form-group label="Język" label-for="v-language-select">
          <v-select
            id="v-language-select"
            label="value"
            placeholder="Wybierz język"
            v-model="form.lang"
            :clearable="false"
            :deselectFromDropdown="false"
            :options="languages"
            @input="
              $emit('fetchByLocale', {
                key: form.page.key,
                lang: form.lang.lang,
              })
            " />
        </b-form-group>

        <b-form-group label="Tytuł" label-for="v-image-title">
          <validation-provider name="Tytuł" rules="required|max:150" v-slot="{ errors }">
            <b-form-input id="v-image-title" placeholder="Tytuł" v-model="form.title" />
            <small v-if="errors[0]" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Zdjęcie nagłówkowe" label-for="v-image">
          <b-button @click="toggleShowModal('title_image')"> Wybierz zdjęcie </b-button>
          <validation-provider #default="{ errors }" name="Logo" rules="required|size:5000">
            <!-- workaround for validation-provider -->
            <input v-if="files.title_image" type="hidden" name="Logo" v-model="files.title_image.file" />
            <input v-else type="hidden" name="Logo" v-model="files.title_image" />

            <ImageSelector
              v-if="showImagesModal.title_image"
              :files="filesArray"
              :show-modal="showImagesModal.title_image"
              @closeModal="toggleShowModal('title_image')"
              @submit="(fileList) => handleFile(fileList[0], 'title_image')" />
            <small class="text-danger d-block">{{ errors[0] }}</small>
          </validation-provider>

          <ImagePreview
            v-if="files.title_image"
            :image="files.title_image"
            @deleteImage="() => handleDelete('title_image')" />
        </b-form-group>
      </b-col>

      <b-col>
        <h4 class="mt-3 mb-2">Opis</h4>
        <b-form-group label="Opis" label-for="v-image">
          <validation-provider #default="{ errors }" name="Opis" rules="required">
            <b-form-textarea v-model="form.description" rows="6" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col class="mt-5">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          :disabled="!this.form.lang.lang">
          Zapisz
        </b-button>
      </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

import FileUploadMixin from '@/mixins/FileUploadMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin.vue';
import { getLanguages } from '@/constants/languages';

import ImageSelector from '@/components/shared/ImageSelector.vue';
import ImagePreview from '@/components/shared/ImagePreview.vue';

export default {
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  mixins: [FileUploadMixin, NotificationMixin],
  components: {
    quillEditor,
    ImageSelector,
    ImagePreview,
  },

  data() {
    return {
      validationForm: null,
      languages: getLanguages(),
      sitesAvailable: [
        {
          value: 'Strona główna',
          key: 'home',
        },
        {
          value: 'Outlet',
          key: 'outlet',
        },
        {
          value: 'Gdzie kupić?',
          key: 'where-to-buy',
        },
        {
          value: 'Inspiracje',
          key: 'inspirations',
        },
        {
          value: 'Blog',
          key: 'blog',
        },
        {
          value: 'Serwis',
          key: 'service',
        },
        {
          value: 'Ogólne warunki sprzedaży',
          key: 'general-terms-of-sale',
        },
        {
          value: 'Polityka prywatności',
          key: 'privacy-policy',
        },
        {
          value: 'Regulamin',
          key: 'terms-and-conditions',
        },
        {
          value: 'Materiały do pobrania',
          key: 'download-materials',
        },
        {
          value: 'Metody dostawy',
          key: 'delivery-methods',
        },
        {
          value: 'Reklamacje i zwroty',
          key: 'complaints-and-returns',
        },
        {
          value: 'O nas',
          key: 'about-us',
        },
        {
          value: 'Kariera',
          key: 'career',
        },
        {
          value: 'Kontakt',
          key: 'contact',
        },
        {
          value: 'Współpraca',
          key: 'cooperation',
        },
        {
          value: 'Pressroom',
          key: 'pressroom',
        },
        {
          value: 'Komunikacja z akcjonariuszami',
          key: 'shareholders-communication',
        },
      ],
      form: {
        page: null,
        title: null,
        description: null,
        title_image: null,
        lang: {
          lang: 'pl',
          value: 'Polski',
        },
      },
    };
  },

  created() {
    this.setFileUploadData(['title_image'], ['title_image']);
  },

  watch: {
    pageData: {
      async handler(data) {
        await this.setImage(data.title_image, 'title_image');

        this.form = {
          ...data,
          page: this.sitesAvailable.find((site) => site.key === data.page),
          lang: this.languages.find((lang) => lang.lang === data.lang),
        };
      },
      deep: true,
    },
  },

  methods: {
    handleChangePage(page) {
      this.lang = {
        lang: 'pl',
        value: 'Polski',
      };

      this.$emit('fetchData', { key: page.key });
    },
    handleUpdate() {
      this.$refs.validationObserver.validate().then((success) => {
        if (success) {
          this.$emit('updateSeoPage', {
            ...this.form,
            lang: this.form.lang.lang,
            page: this.form.page.key,
            title_image: this.files.title_image,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
