var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-form',{staticClass:"form-container",on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdate($event)}}},[_c('b-col',[_c('h4',{staticClass:"mb-2"},[_vm._v("Wybierz stronę")]),_c('b-form-group',{attrs:{"label":"Strona","label-for":"v-page-select"}},[_c('v-select',{attrs:{"id":"v-page-select","label":"value","placeholder":"Wybierz stronę","clearable":false,"deselectFromDropdown":false,"options":_vm.sitesAvailable},on:{"input":_vm.handleChangePage},model:{value:(_vm.form.page),callback:function ($$v) {_vm.$set(_vm.form, "page", $$v)},expression:"form.page"}})],1),_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Konfiguracja")]),_c('b-form-group',{attrs:{"label":"Język","label-for":"v-language-select"}},[_c('v-select',{attrs:{"id":"v-language-select","label":"value","placeholder":"Wybierz język","clearable":false,"deselectFromDropdown":false,"options":_vm.languages},on:{"input":function($event){return _vm.$emit('fetchByLocale', {
              key: _vm.form.page.key,
              lang: _vm.form.lang.lang,
            })}},model:{value:(_vm.form.lang),callback:function ($$v) {_vm.$set(_vm.form, "lang", $$v)},expression:"form.lang"}})],1),_c('b-form-group',{attrs:{"label":"Tytuł","label-for":"v-image-title"}},[_c('validation-provider',{attrs:{"name":"Tytuł","rules":"required|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"v-image-title","placeholder":"Tytuł"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Zdjęcie nagłówkowe","label-for":"v-image"}},[_c('b-button',{on:{"click":function($event){return _vm.toggleShowModal('title_image')}}},[_vm._v(" Wybierz zdjęcie ")]),_c('validation-provider',{attrs:{"name":"Logo","rules":"required|size:5000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [(_vm.files.title_image)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image.file),expression:"files.title_image.file"}],attrs:{"type":"hidden","name":"Logo"},domProps:{"value":(_vm.files.title_image.file)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files.title_image, "file", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.files.title_image),expression:"files.title_image"}],attrs:{"type":"hidden","name":"Logo"},domProps:{"value":(_vm.files.title_image)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.files, "title_image", $event.target.value)}}}),(_vm.showImagesModal.title_image)?_c('ImageSelector',{attrs:{"files":_vm.filesArray,"show-modal":_vm.showImagesModal.title_image},on:{"closeModal":function($event){return _vm.toggleShowModal('title_image')},"submit":function (fileList) { return _vm.handleFile(fileList[0], 'title_image'); }}}):_vm._e(),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.files.title_image)?_c('ImagePreview',{attrs:{"image":_vm.files.title_image},on:{"deleteImage":function () { return _vm.handleDelete('title_image'); }}}):_vm._e()],1)],1),_c('b-col',[_c('h4',{staticClass:"mt-3 mb-2"},[_vm._v("Opis")]),_c('b-form-group',{attrs:{"label":"Opis","label-for":"v-image"}},[_c('validation-provider',{attrs:{"name":"Opis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary","disabled":!this.form.lang.lang}},[_vm._v(" Zapisz ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }