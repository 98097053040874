<template>
  <b-card>
    <form-component
      :page-data="form"
      @updateSeoPage="updateSeoPage"
      @fetchData="fetchData"
      @fetchByLocale="fetchByLocale" />
  </b-card>
</template>

<script>
import FormComponent from '@/components/pages/seo/FormComponent';
import NotificationMixin from '@/mixins/NotificationMixin';
import { createOrUpdate, show } from '@/http/static-pages/seo';

export default {
  mixins: [NotificationMixin],
  components: {
    FormComponent,
  },

  data() {
    return {
      form: {
        title: '',
        description: '',
        page: null,
        lang: null,
      },
    };
  },

  methods: {
    fetchData({ key }) {
      show(key, 'pl')
        .then(({ data }) => {
          this.form = data.data;
        })
        .catch(() => {
          this.form = {
            title: '',
            description: '',
            page: null,
            lang: {
              lang: 'pl',
              value: 'Polski',
            },
            title_image: null,
          };

          this.showErrorNotification('Nie udało się pobrać danych', 'Spróbuj ponownie później');
        });
    },
    updateSeoPage(data) {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key === 'title_image') {
          formData.append(key, data[key].file);
        } else {
          formData.append(key, data[key]);
        }
      });

      createOrUpdate(formData)
        .then(() => {
          this.showSuccessNotification('Dane zostały zaktualizowane', 'Dane SEO zostały pomyślnie zaktualizowane');
        })
        .catch(() => {
          this.showErrorNotification('Nie udało się zaktualizować', 'Skontaktuj się ze swoim developerem');
        });
    },
    fetchByLocale({ key, lang }) {
      show(key, lang)
        .then(({ data }) => {
          this.form = data.data;
        })
        .catch(() => {
          this.showErrorNotification('Nie udało się pobrać danych', 'Spróbuj ponownie później');
        });
    },
  },
};
</script>
